<template>
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 10C10.8191 10 13.1045 7.76142 13.1045 5C13.1045 2.23858 10.8191 0 8 0C5.18088 0 2.89553 2.23858 2.89553 5C2.89553 7.76142 5.18088 10 8 10ZM0.0327134 18.0122C0.853913 13.9981 4.10923 11 8 11C9.53377 11 10.9688 11.4659 12.1949 12.2763C9.76326 13.0424 8 15.3153 8 18V20H1.87463C0.746982 20 -0.188853 19.0953 0.0327134 18.0122ZM13 14C13.5523 14 14 14.4477 14 15V16H15C15.5523 16 16 16.4477 16 17C16 17.5523 15.5523 18 15 18H14V19C14 19.5523 13.5523 20 13 20C12.4477 20 12 19.5523 12 19V18H11C10.4477 18 10 17.5523 10 17C10 16.4477 10.4477 16 11 16H12V15C12 14.4477 12.4477 14 13 14Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
import { Vue } from "vue-class-component";

export default class BookingClient extends Vue {}
</script>
